<!--
 * @Description: 企业管理基本详情组件
 * @Author: HuangCheng
 * @Date: 2020-03-10 10:40:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-13 17:10:05
 -->
<template>
  <section class="enterprise-detail">
    <a-menu style="width: 126px;height: 100%" mode="vertical" :defaultSelectedKeys="[type]" @click="handleMenuClick">
      <a-menu-item key="1" >基本信息</a-menu-item>
      <a-menu-item key="2" >合同信息</a-menu-item>
      <a-menu-item key="3" >开票范围</a-menu-item>
      <a-menu-item key="4" >配置信息</a-menu-item>
      <a-menu-item key="5" >企业认证</a-menu-item>
      <a-menu-item key="6" >开票信息</a-menu-item>
      <!-- <a-menu-item key="7" v-has="'enterprise:moreflpz'">返利配置</a-menu-item> -->
      <a-menu-item key="8" >费用配置</a-menu-item>
      <a-menu-item key="9" >邮寄信息</a-menu-item>
      <!-- <a-menu-item key="10" >提成配置</a-menu-item> -->
    </a-menu>
    <section class="detail-content">
      <!-- 基本信息 -->
      <div v-show="type === '1'">
        <a-form class="detail-form">
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="企业编号">
            <span>{{detailInfo.id}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="企业名称">
            <span>{{detailInfo.enterpriseName}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="纳税人类型">
            <span>{{detailInfo.taxpayerType | getArrItem(taxpayerType)}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="企业状态">
            <span>{{detailInfo.status === '101101' ? '不正常/未审核' : detailInfo.status === '101102' ? '不正常/停用' : detailInfo.status === '101111' ? '正常/审核成功' : ''}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="联系人">
            <span>{{detailInfo.enterpriseContacts}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="联系电话">
            <span>{{detailInfo.enterprisePhone || ''}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="创建时间">
            <span>{{detailInfo.createTime}}</span>
          </a-form-item>
        </a-form>
      </div>
      <!-- 合同信息 -->
      <div v-show="type === '2'">
        <ContractInfo :enterpriseId="id" />
      </div>
      <!-- 开票范围 -->
      <div v-show="type === '3'">
        <a-card :bordered="false">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="24">
                <a-col :lg="6" :md="8" :sm="12">
                  <a-form-item label="状态">
                    <a-select style="width: 200px" placeholder="请选择状态" v-model="searchParams.status">
                      <a-select-option value="100201">正常</a-select-option>
                      <a-select-option value="100202">停用</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :lg="6" :md="8" :sm="12">
                  <span class="table-page-search-submitButtons">
                    <a-button type="primary" @click="handleToSearchScope">查询</a-button>
                    <a-button style="margin-left: 8px" icon="reload" @click="resetSearchScope">重置</a-button>
                    <a-button style="margin-left: 8px" type="primary" icon="plus" @click="() => this.handleModalVisible()">添加</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>

          <s-table
            ref="table"
            size="default"
            :rowKey="(record, index) => index"
            :columns="scopeColumns"
            :data="loadScopeList"
            :pagination="pagination"
            bordered
          >
            <span slot="action" slot-scope="text, record" v-if="record.status === '100201'">
              <a @click="freeStaff(record)">冻结</a>
            </span>
            <span slot="action" slot-scope="text, record" v-else>
              <a @click="returnOk(record)">解冻</a>
            </span>
          </s-table>
        </a-card>
        <!-- 添加 -->
        <a-modal title="添加" :visible="addModal" @ok="handleScopeModalOk" @cancel="handleScopeModalCancel">
          <a-form :form="addScopeForm" class="config-form">
            <a-form-item label="开票范围" :labelCol="{lg: {span: 4}, sm: {span: 7}}" :wrapperCol="{lg: {span: 20}, sm: {span: 17} }">
              <a-select style="width: 100%" placeholder="请选择开票范围" showSearch :defaultActiveFirstOption="false" :filterOption="false" @search="handleSearchSelectCompany" mode="multiple" @change="handleChangeSelectCompany" v-decorator="['businessScopeId', {rules:[{ required: true, message: '请选择开票范围', type: 'array' }]}]">
                <a-select-option v-for="item in scopeRateList" :key="item.id" :value="item.id">{{item.businessScope}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-modal>
      </div>
      <!-- 配置信息 -->
      <div v-show="type === '4'">
        <a-form :form="configForm" class="config-form">
          <a-row class="form-row" :gutter="16">
            <a-col :lg="24" :md="24" :sm="24">
              <p style="color: #333333;font-size: 16px">配置信息</p>
            </a-col>
          </a-row>
         
          <a-row class="form-row" :gutter="16">
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="企业绑定手机号" :labelCol="configLabelCol" :wrapperCol="configWrapperCol">
                <span v-if="modalType === 'detail'">{{configDetail.bind_phone}}</span>
                <a-input v-else placeholder="请输入企业绑定手机号"
                  v-decorator="[
                  'bind_phone', {rules: [{validator: validatePhone},{ required: true, message: '请输入企业绑定联系人', whitespace: true}]}]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="企业绑定邮箱" :labelCol="configLabelCol" :wrapperCol="configWrapperCol">
                <span v-if="modalType === 'detail'">{{configDetail.bind_mail}}</span>
                <a-input v-else placeholder="请输入企业绑定邮箱"
                  v-decorator="[
                  'bind_mail', {rules: [{type:'email',message:'邮箱格式不正确'},{ required: true, message: '请输入企业绑定邮箱', whitespace: true}]}]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="单笔限额" :labelCol="configLabelCol" :wrapperCol="configWrapperCol">
                <span v-if="modalType === 'detail'">{{configDetail.quotas_bank}}</span>
                <a-input v-else placeholder="请输入单笔限额"
                  v-decorator="['quotas_bank', {rules: [{ required: true, message: '请输入单笔限额', whitespace: true}]}]" />
              </a-form-item>
            </a-col>
          </a-row>
          <!-- <a-row class="form-row" :gutter="16">
            <a-col :lg="24" :md="24" :sm="24">
              <p style="color: #333333;font-size: 16px">手续费信息</p>
            </a-col>
          </a-row>
          <a-row class="form-row" :gutter="16">
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="企业手续费扣点" :labelCol="configLabelCol" :wrapperCol="configWrapperCol">
                <span v-if="modalType === 'detail'">{{configDetail.service_charge_point}}</span>
                <a-input v-else placeholder="请输入企业手续费扣点"
                  v-decorator="[
                  'service_charge_point', {
                    rules: [{ required: true, message: '请输入企业手续费扣点', whitespace: true}]}]"
                />
              </a-form-item>
            </a-col>
          </a-row> -->
          <a-row class="form-row" :gutter="16">
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="" :wrapperCol="configWrapperCol">
                <a-button style="margin-left: 66px" type="primary" @click="handleConfigSubmit" :loading="loading">
                  {{modalType === 'detail' ? '修改' : '提交'}}
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 企业认证 -->
      <div v-show="type === '5'" class="company-indentify">
        <a-form-model ref="companyIdentifyRule" :model="companyIdentifyParams" :rules="companyIdentifyRules" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <!-- <a-form-model-item label="企业编码" required>
            <input class="extra-input" v-model="companyIdentifyParams.enterpriseId" readonly/>
          </a-form-model-item> -->
          <a-form-model-item label="工商注册名称" prop="realName" ref="realName">
            <a-input
              v-model="companyIdentifyParams.realName" @blur="() => {$refs.realName.onFieldBlur()}"
            />
          </a-form-model-item>
          <a-form-model-item label="企业信用代码" prop="realCode" ref="realCode">
            <a-input
              v-model="companyIdentifyParams.realCode" @blur="() => {$refs.realCode.onFieldBlur()}"
            />
          </a-form-model-item>
          <a-form-model-item label="过期时间" prop="remarkDate" required>
            <a-date-picker :allowClear='false' v-model="companyIdentifyParams.remarkDate" :value="timeValue" @change='changeTime' style="width: 100%;"/>
          </a-form-model-item>
          <a-form-model-item label="营业执照" prop="enterpriseLicense" required>
            <a-upload
              name="avatar"
              listType="picture-card"
              class="avatar-uploader"
              :action="url.frontUrl"
              :headers="headers"
              :showUploadList="false"
              :beforeUpload="beforeUpload"
              @change="handleChange($event, 'enterpriseLicense')"
              v-decorator="['enterpriseLicense', { rules: [{ required: true, message: '请上传营业执照!' }] }]"
            >
              <img class="picture-common" v-if="enterpriseLicense" :src="enterpriseLicense" alt="avatar" />
              <div v-else>
                <a-icon :type="loadingBusinessLicence ? 'loading' : 'plus'" />
                <div class="ant-upload-text">营业执照</div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="企业现场照片">
            <a-upload
              name="企业现场照片"
              listType="picture-card"
              class="avatar-uploader"
              :action="url.frontUrl"
              :headers="headers"
              :showUploadList="false"
              :beforeUpload="beforeUpload"
              @change="handleChange($event, 'sitePhotos1')"
            >
              <img class="picture-common" v-if="sitePhotos1" :src="sitePhotos1" alt="avatar" />
              <div v-else>
                <a-icon :type="loadingidCardFront ? 'loading' : 'plus'" />
                <div class="ant-upload-text">企业现场照片</div>
              </div>
            </a-upload>
            <a-upload
              name="企业现场照片"
              listType="picture-card"
              class="avatar-uploader"
              :action="url.frontUrl"
              :headers="headers"
              :showUploadList="false"
              :beforeUpload="beforeUpload"
              @change="handleChange($event, 'sitePhotos2')"
            >
              <img class="picture-common" v-if="sitePhotos2" :src="sitePhotos2" alt="avatar" />
              <div v-else>
                <a-icon :type="loadingidCardOpsite ? 'loading' : 'plus'" />
                <div class="ant-upload-text">企业现场照片</div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-button type="primary" @click="handleSubmitCompanyIdentify">
              提交
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <!-- 开票信息 -->
      <div v-show="type === '6'" class="company-indentify">
        <a-form-model ref="bankInfoRule" :model="bankInfoParams" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <!-- <a-form-model-item label="企业编码" prop="enterpriseId" ref="enterpriseId">
            <a-input v-model="bankInfoParams.enterpriseId" @blur="() => {$refs.enterpriseId.onFieldBlur()}" read-only/>
          </a-form-model-item> -->
          <a-form-model-item label="户名" prop="payeeName" ref="payeeName">
            <a-input disabled v-model="bankInfoParams.payeeName" @blur="() => {$refs.payeeName.onFieldBlur()}" read-only/>
          </a-form-model-item>
          <a-form-model-item label="纳税人识别号" prop="realCode" ref="realCode">
            <a-input disabled v-model="companyIdentifyParams.realCode" @blur="() => {$refs.payeeName.onFieldBlur()}" read-only/>
          </a-form-model-item>
          <a-form-model-item label="开户行" prop="bankName" ref="bankName">
            <a-input v-model="bankInfoParams.bankName" @blur="() => {$refs.bankName.onFieldBlur()}"/>
          </a-form-model-item>
          <a-form-model-item label="账号" prop="payeeBankNo" ref="payeeBankNo">
            <a-input v-model="bankInfoParams.payeeBankNo" @blur="() => {$refs.payeeBankNo.onFieldBlur()}"/>
          </a-form-model-item>
          <a-form-model-item label="电话" prop="enterprisePhone" ref="enterprisePhone">
            <a-input v-model="detailInfo.enterprisePhone" @blur="() => {$refs.enterprisePhone.onFieldBlur()}"/>
            <!-- {{detailInfo.enterprisePhone}} -->
          </a-form-model-item>
          <a-form-model-item label="地址" prop="enterpriseAddress" ref="enterpriseAddress">
            <a-input v-model="detailInfo.enterpriseAddress" @blur="() => {$refs.enterpriseAddress.onFieldBlur()}"/>
            <!-- {{detailInfo.enterpriseAddress}} -->
          </a-form-model-item>
          <a-form-model-item style="margin:20px 50px">
            <a-button type="primary" @click="handleSubmitBankInfo">提交</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <!-- 返利配置 -->
      <div v-show="type === '7'" class="company-indentify">
        <a-form-model ref="rebate" :model="rebateParams" :rules="rebateRules" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-form-model-item label="是否返利" prop="rebatesState">
            <a-select v-model="rebateParams.rebatesState" placeholder="请选择是否返利" @change="selectIsRebate">
              <a-select-option value="103401">
                是
              </a-select-option>
              <a-select-option value="103402">
                否
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="返利周期" prop="rebatesCycle" v-if="selectRebate">
            <a-select v-model="rebateParams.rebatesCycle" placeholder="请选择返利周期">
              <a-select-option value="103501">
                按月
              </a-select-option>

              <!-- <a-select-option value="103502">
                按季度
              </a-select-option>
              <a-select-option value="103503">
                按年
              </a-select-option> -->
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="返利范围" prop="rebaterange" v-if="selectRebate">
            <table class="tbl" cellpadding='0' cellspacing='0'>
              <tr>
                <td>起始点(元)</td>
                <td>结束点(元)
                  <a-tooltip>
                    <template slot="title">
                      包含结束点
                    </template>
                    <a-button style="color:orange" type='link' shape="circle" icon="info-circle"></a-button>
                  </a-tooltip>
                </td>
                <td>扣点(%)</td>
                <td>操作</td>
              </tr>
              <tr v-for="(item, index) in rebateraneLists" :key="index">
                <td><a-input class="input" type="number" placeholder="请输入起始点" readOnly v-model="item.ruleBegin" /></td>
                <td><a-input class="input" type="number" placeholder="请输入结束点" v-model="item.ruleEnd" /></td>
                <td><a-input class="input" type="number" placeholder="请输入扣点" v-model="item.feePoint" /></td>
                <td>
                  <div class="operation">
                    <a-button type="dashed" icon="delete" shape="circle" v-show="rebateraneLists.length>1 && index > 0" @click="delRebate(index)" style="margin-right:10px"></a-button>
                    <a-button type="primary" icon="plus" shape="circle" v-show="index===rebateraneLists.length-1" @click="addRebate(index)"></a-button>
                  </div>
                </td>
              </tr>
            </table>
            <!-- <ul class="range">
              <li>
                <a-input class="input" placeholder="Basic usage" value="起始点(元)" readOnly />
                <a-input class="input" placeholder="Basic usage" value="结束点(元)" readOnly />
                <a-input class="input" placeholder="Basic usage" value="扣点(%)" readOnly />
                <div class="operation">操作</div>
              </li>
              <li v-for="(item, index) in rebateraneLists" :key="index">
                <a-input class="input" type="number" placeholder="请输入起始点" readOnly v-model="item.ruleBegin" />
                <a-input class="input" type="number" placeholder="请输入结束点" v-model="item.ruleEnd" />
                <a-input class="input" type="number" placeholder="请输入扣点" v-model="item.feePoint" />
                <div class="operation">
                  <a-button type="dashed" icon="delete" shape="circle" v-show="rebateraneLists.length>1 && index > 0" @click="delRebate(index)" style="margin-right:10px"></a-button>
                  <a-button type="primary" icon="plus" shape="circle" v-show="index===rebateraneLists.length-1" @click="addRebate(index)"></a-button>
                </div>
              </li>
            </ul> -->
          </a-form-model-item>
          <a-form-model-item style="margin:20px 50px">
            <a-button type="primary" @click="handleSubmitRebate" v-if="rebateId===0">新增</a-button>
            <a-button type="primary" @click="handleSubmitEditRebate" v-else>修改</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <!-- 费用配置 -->
      <div v-show="type === '8'">
        <div style="display:flex;align-items:center;margin:0 0 10px 0;">
          <span>代理商：</span>
          <span v-if="dlsInfo" style="margin-right:20px">{{dlsInfo.userName}}</span>
          <span v-else style="margin-right:20px">--</span>
          <span>结算方式：</span>
          <span v-if="dlsInfo">{{dlsInfo.settlementMode==='100402'?'汇总结算':'单笔结算'}}</span>
          <span v-else style="margin-right:20px">--</span>
          <a-button type="primary" style="margin-left:10px" @click="handleSubmitEditCharge" v-if="allowAuthor">新增</a-button>
          <a-button type="primary" @click="freeAthour" style="margin-left:10px" v-else>审核</a-button>
        </div>
        <div style="margin-bottom:30px" v-if="dlsInfo">
          <h1>费用：</h1>
          <table class="tbl" cellpadding='0' cellspacing='0'>
            <tr>
              <td>类型</td>
              <td>起始费用(元)</td>
              <td>结束费用(元)
                <a-tooltip>
                  <template slot="title">
                    包含结束费用
                  </template>
                  <a-button style="color:orange" type='link' shape="circle" icon="info-circle"></a-button>
                </a-tooltip>
              </td>
              <td>费率(%)</td>
            </tr>
            <tr v-for="(item, index) in checkFeeResult" :key="index">
              <td>阶梯{{index+1}}</td>
              <td>{{item.ruleBegin}}</td>
              <td>{{item.ruleEnd}}</td>
              <td>{{item.feePoint}}</td>
            </tr>
          </table>
        </div>
        <div style="margin-bottom:30px" v-if="dlsInfo&&dlsInfo.settlementMode==='100401'">
          <h1>提成：</h1>
          <table class="tbl" cellpadding='0' cellspacing='0'>
            <tr>
              <td>类型</td>
              <td>起始费用(元)</td>
              <td>结束费用(元)
                <a-tooltip>
                  <template slot="title">
                    包含结束费用
                  </template>
                  <a-button style="color:orange" type='link' shape="circle" icon="info-circle"></a-button>
                </a-tooltip>
              </td>
              <td>提成(%)</td>
            </tr>
            <tr v-for="(item, index) in checkFeeResult" :key="index">
              <td>阶梯{{index+1}}</td>
              <td>{{item.ruleBegin}}</td>
              <td>{{item.ruleEnd}}</td>
              <td>{{item.salesPoint}}</td>
            </tr>
          </table>
        </div>
        <a-modal title="新增" width="1100px" :confirm-loading="confirmLoadingFee" destroyOnClose :visible="visibleExamineModalFee" @ok="handleRechargeModalOkFee" @cancel="handleExamineModalCancelFee">
          <!-- <div style="margin:0 0 20px 0">
            <span>代理商：</span>
            <a-select
              placeholder="请选择代理商"
              style="width: 80%"
              v-model="selectDls"
              @change="changeDls"
            >
              <a-select-option v-for="item in salesmanLists" :key="item" :value="item.userId">{{item.userName}}--{{item.settlementMode==='100402'?'汇总结算':'单笔结算'}}</a-select-option>
            </a-select>
          </div> -->
          <table class="tbl" cellpadding='0' cellspacing='0'>
            <tr>
              <td>类型</td>
              <td>起始费用(元)</td>
              <td>结束费用(元)
                <a-tooltip>
                  <template slot="title">
                    包含结束费用
                  </template>
                  <a-button style="color:orange" type='link' shape="circle" icon="info-circle"></a-button>
                </a-tooltip>
              </td>
              <td>费率(%)</td>
              <td>提成(%)</td>
              <td>操作</td>
            </tr>
            <tr v-for="(item, index) in chargeLists" :key="index">
              <td>阶梯{{index+1}}</td>
              <td><a-input class="input" type="number" placeholder="请输入起始费用" v-model="item.ruleBegin" /></td>
              <td><a-input class="input" type="number" placeholder="请输入结束费用" v-model="item.ruleEnd" /></td>
              <td><a-input class="input" type="number" placeholder="请输入费用利率" v-model="item.feePoint" /></td>
              <td><a-input class="input" type="number" placeholder="请输入提成" :disabled="allowInput" v-model="item.salesPoint" /></td>
              <td>
                <div class="operation">
                  <a-button type="dashed" icon="delete" shape="circle" v-show="index===chargeLists.length-1&&index>0" @click="delCharge(index,item)" style="margin-right:10px"></a-button>
                  <a-button type="primary" icon="plus" shape="circle" v-show="index===chargeLists.length-1" @click="addCharge(index)"></a-button>
                </div>
              </td>
            </tr>
          </table>
        </a-modal>
        <!-- 审核 -->
        <a-modal
          title="审核"
          width="1100px"
          :visible="visibleVerfiy"
          :confirm-loading="confirmLoadingVerfiy"
          @ok="handleOkVerfiy"
          @cancel="handleCancelVerfiy"
        >
          <div style="margin-bottom:30px" v-if="dlsInfo">
            <h4>费用：</h4>
            <table class="tbl" cellpadding='0' cellspacing='0'>
              <tr>
                <td>类型</td>
                <td>起始费用(元)</td>
                <td>结束费用(元)
                  <a-tooltip>
                    <template slot="title">
                      包含结束费用
                    </template>
                    <a-button style="color:orange" type='link' shape="circle" icon="info-circle"></a-button>
                  </a-tooltip>
                </td>
                <td>费率(%)</td>
              </tr>
              <tr v-for="(item, index) in checkAuditFeeResult" :key="index">
                <td>阶梯{{index+1}}</td>
                <td>{{item.ruleBegin}}</td>
                <td>{{item.ruleEnd}}</td>
                <td>{{item.feePoint}}</td>
              </tr>
            </table>
          </div>
          <div style="margin-bottom:30px" v-if="dlsInfo&&dlsInfo.settlementMode!=='100402'">
            <h4>提成：</h4>
            <table class="tbl" cellpadding='0' cellspacing='0'>
              <tr>
                <td>类型</td>
                <td>起始费用(元)</td>
                <td>结束费用(元)
                  <a-tooltip>
                    <template slot="title">
                      包含结束费用
                    </template>
                    <a-button style="color:orange" type='link' shape="circle" icon="info-circle"></a-button>
                  </a-tooltip>
                </td>
                <td>提成(%)</td>
              </tr>
              <tr v-for="(item, index) in checkAuditFeeResult" :key="index">
                <td>阶梯{{index+1}}</td>
                <td>{{item.ruleBegin}}</td>
                <td>{{item.ruleEnd}}</td>
                <td>{{item.salesPoint}}</td>
              </tr>
            </table>
          </div>
          <a-radio-group name="radioGroup" v-model="selectRadio">
            <a-radio value="102211">
              通过
            </a-radio>
            <a-radio value="102231">
              未通过
            </a-radio>
          </a-radio-group>
        </a-modal>
      </div>
      <!-- 邮递地址 -->
      <div v-show="type === '9'" class="company-indentify">
        <EmailInfo :enterpriseId="id" style="width:100%"></EmailInfo>
        <!-- <a-form :form="addressform" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmitaddress">
          <a-form-item label="收件人姓名">
            <a-input
              v-decorator="['contactsName', { rules: [{ required: true, message: '请输入联系人姓名!' }] }]"
            />
          </a-form-item>
          <a-form-item label="联系电话">
            <a-input
              v-decorator="['contactsPhone', { rules: [{ required: true, message: '请输入联系人电话!' }] }]"
            />
          </a-form-item>
          <a-form-item label="联系地址">
            <a-textarea
              v-decorator="['contactsAddress', { rules: [{ required: true, message: '请输入联系人地址!' }] }]"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-button type="primary" html-type="submit">
              提交
            </a-button>
          </a-form-item>
        </a-form> -->
      </div>
      <!-- 提成配置 -->
      <div v-show="type === '10'">
        <CommissionConfig :enterpriseId="id" />
      </div>
    </section>
  </section>
</template>
<script>
  import STable from '@/components/table/'
  import AInput from "ant-design-vue/es/input/Input"
  import moment from 'moment'
  import Vue from 'vue'
  import { ACCESS_TOKEN, USER_INFO } from "@/store/mutation-types"
  import ContractInfo from './ContractInfo.vue'
  import CommissionConfig from './commissionConfig'
  import EmailInfo from './email.vue'
  import {
    getEnterpriseBaseInfo,
    getEnterpriseConfig,
    updateEnterpriseConfig,
    getEnterpriseScopeList,
    addEnterpriseBusinessScope,
    getBusinessScopeBox,
    freezeEnterpriseBusinessScope,
    updateCompanyBankInfo,
    updateCompanyIndentifyInfo,
    uploadFile,
    getCompanyIndentifyInfo,
    addCompanyBankInfo,
    getCompanyBankInfo,
    addRebateRules,
    updataRebateRules,
    getEnterpriseInvoiceMailed,
    saveEnterpriseInvoiceMailed,
    taxEnterpriseServiceRuleItemDel,
    taxEnterpriseServiceRuleItemEdit,
    feeAudit,
    getDlsInfo,
    feeAdd,
    feeQuery,
    getAgentBox,
    getUserLoginInfoNowAccount,
  } from '@/api/api';
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  export default {
    name: "EnterpriseDetail",
    components: {
      AInput,
      STable,
      ContractInfo,
      EmailInfo,
      CommissionConfig
    },
    data () {
      return {
        selectRadio: '102211',
        visibleVerfiy: false,
        addressform: this.$form.createForm(this),
        taxpayerType: [{
          value: '102401',
          label: '一般纳税人'
        }, {
          value: '102411',
          label: '小规模纳税人'
        }],
        detailInfo: {
          id: '',
          enterpriseName: '',
          status: '',
          enterpriseContacts: '',
          enterprisePhone: '',
          createTime: ''
        },
        type: '1',
        salesmanLists: [],
        // 开票范围
        searchParams: {
        },
        scopeColumns: [
          // {
          //   title: '序号',
          //   customRender:(text,record,index)=>`${index+1}`
          // },
          {
            title: '开票ID',
            align: 'center',
            dataIndex: 'businessScopeId'
          },
          {
            title: '开票内容',
            align: 'center',
            dataIndex: 'businessScopeName'
          },
          {
            title: '服务费扣点',
            align: 'center',
            dataIndex: 'taxRate',
            customRender: (text) => (text * 100) + '%'
          },
          {
            title: '状态',
            align: 'center',
            dataIndex: 'status',
            customRender: (text) => text === '100201' ? '正常' : text === '100202' ? '停用' : ''
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'createTime'
          },
          {
            title: '操作',
            align: 'center',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: { customRender: 'action' },
          }
        ],
        addModal: false,
        addScopeForm: this.$form.createForm(this), // 添加表单
        scopeRateList: [],
        pagination: {},
        // 配置信息
        configForm: this.$form.createForm(this),
        configDetail: {
          account_balance_remind_email: '',
          account_balance_remind_phone: '',
          account_balance_remind_type: '',
          bank_balance_remind_pric: '',
          bill_remind_email: '',
          bind_mail: '',
          bind_phone: '',
          early_warning_remind_email: '',
          quotas_bank: '',
          quotas_wx: '',
          quotas_zfb: '',
          recharge_remind_email: '',
          recharge_remind_phone: '',
          recharge_remind_type: '',
          service_charge_bear: '',
          service_charge_point: '',
          tax_remind_email: '',
          wx_balance_remind_pric: '',
          zfb_balance_remind_pric: ''
        },
        modalType: 'detail',
        configLabelCol: {
          lg: {span: 10},
          md: {span: 12}
        },
        configWrapperCol: {
          lg: {span: 14},
          md: {span: 12}
        },
        loading: false,
        companyIdentify: this.$form.createForm(this),
        companyIdentifyRules: {
          realName: [{ required: true, message: '请输入工商注册名称', trigger: 'blur' }],
          realCode: [{ required: true, message: '请输入企业信用代码', trigger: 'blur' }],
          remarkDate: [{ required: true, message: '请输入过期时间', trigger: 'change' }],
          enterpriseLicense: [{ required: true, message: '请上传营业执照', trigger: 'change' }],
          sitePhotos1: [{ required: true, message: '请上传身份证照片', trigger: 'change' }],
        },
        timeValue: undefined,
        loadingBusinessLicence: false,
        loadingidCardFront: false,
        loadingidCardOpsite: false,
        sitePhotos2: '', // 身份证反面
        sitePhotos1: '', // 身份证正面
        enterpriseLicense: '', // 营业执照
        companyIdentifyParams: {
          enterpriseId: '', // 企业编码
          realName: '', // 企业工商注册名称
          realCode: '', // 企业信用代码
          expirationDate: undefined, // 过期时间
          remarkDate: undefined,
          sitePhotos2: '', // 身份证反面
          sitePhotos1: '', // 身份证正面
          enterpriseLicense: '', // 营业执照
        }, // 企业认证参数
        rules: {
          enterpriseId: [{ required: true, message: '请输入企业编码', trigger: 'blur' }],
          bankName: [{ required: true, message: '请输入银行名称', trigger: 'blur' }],
          payeeBankNo: [{ required: true, message: '请输入账号', trigger: 'blur' }],
          payeeName: [{ required: true, message: '请输入开户人', trigger: 'blur' }],
          subBankName: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
        },
        bankInfoParams: {
          bankName: '', // 银行名称
          enterpriseId: '', // 企业编码
          payeeBankNo: '', // 银行卡号
          payeeName: '', // 开户人
          subBankName: '', // 开户行
          id: ''
        },
        rebateParams: {
          rebatesState: undefined,
          rebatesCycle: undefined
        },
        rebateRules: {
          rebatesState: [{ required: true, message: '请选择是否返利', trigger: 'change' }],
          rebatesCycle: [{ required: true, message: '请选择返利周期', trigger: 'change' }]
        },
        selectRebate: false,
        rebateId: 0,
        chargeId: 0,
        rebateraneLists: [ // 返利配置
          {
            ruleBegin: 0,
            ruleEnd: '',
            feePoint: '',
          }
        ],
        isAddRebate: false,
        isNull: false, // 费用配置信息
        chargeParams: {},
        chargeLists: [
          {
            feePoint: 0,
            salesPoint: 0,
            ruleBegin: 0,
            ruleEnd: 0,
          }
        ],
        isAddCharge: false,
        isNullCharge: false,
        headers: {'X-Access-Token': Vue.ls.get(ACCESS_TOKEN)},
        url: {
          frontUrl: window._CONFIG['domianURL'] + '/api/tax/common/file/upload',
        },
        emailAddress: null,
        getKpInfo: null,
        loading: false,
        finishChargeLists: [],
        showChargeList: false,
        confirmLoadingVerfiy: false,
        allowAuthor: false,
        visibleExamineModalFee: false,
        confirmLoadingFee: false,
        dlsInfo: null,
        checkFeeResult: [],
        checkAuditFeeResult: [],
        selectDls: '',
        allowInput: true,
        nowChannelUserId:'',
      }
    },
    props: {
      id: {
        type: String,
        default: '',
        required: false
      },
      userId: {
        type: String,
        default: '',
        required: false
      },
    },
    created() {
      getAgentBox().then(res => {
        if (res.success) {
          this.salesmanLists = res.result
        }
      })
      this.getEnterpriseBaseInfo();
      this.getEnterpriseConfig();
      this.getCompanyIndentifyInfo();
      this.getEnterpriseBankInfo();
      this.getRebateRules();
      this.getDlsInfos();
      this.getUserLoginInfoNowAccountId();
      // this.getRebateRules('103602');
      // this.getEnterpriseInvoiceMailed();
      // if (this.$store.state.user.userRules.includes('enterprise:morejbxx')) {
      //   this.type = '1'
      // } else if (this.$store.state.user.userRules.includes('enterprise:morehtxx')) {
      //   this.type = '2'
      // } else if (this.$store.state.user.userRules.includes('enterprise:morekpfw')) {
      //   this.type = '3'
      // }
      // else if (this.$store.state.user.userRules.includes('enterprise:morepzxx')) {
      //   this.type = '4'
      // }
      // else if (this.$store.state.user.userRules.includes('enterprise:moreqyrz')) {
      //   this.type = '5'
      // }
      // else if (this.$store.state.user.userRules.includes('enterprise:morekpxx')) {
      //   this.type = '6'
      // }
      // else if (this.$store.state.user.userRules.includes('enterprise:moreflpz')) {
      //   this.type = '7'
      // }
      // else if (this.$store.state.user.userRules.includes('enterprise:morefypz')) {
      //   this.type = '8'
      // }
      // else if (this.$store.state.user.userRules.includes('enterprise:moreyjxx')) {
      //   this.type = '9'
      // } else {
      //   this.type = '0'
      // }
    },
    methods: {
      //查询用户当前登录信息
      getUserLoginInfoNowAccountId(){
        getUserLoginInfoNowAccount().then(res=>{
          if(res.success){
            this.nowChannelUserId = res.result.userId
          }
        })
      },
      // 获取代理商信息
      getDlsInfos () {
        getDlsInfo(this.id).then(res => {
          if (res.success) {
            this.dlsInfo = res.result
            this.selectDls = res.result.userId
            this.allowInput = this.dlsInfo.settlementMode==='100402'?true:false
          }
        })
      },
      // 校验手机号
      validatePhone (rule, value, callback) {
        const regex = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
        if (!regex.test(value)) {
          callback('手机号格式不正确')
        }
        callback()
      },
      /**
       * @description: 基本详情左侧菜单点击事件
       * @param : e
       * @return: void
       * @Author: HuangCheng
       */
      handleMenuClick(e) {
        this.type = e.key;
      },
      /**
       * @description: 获取企业详情
       * @Author: HuangCheng
       */
      getEnterpriseBaseInfo() {
        getEnterpriseBaseInfo(this.id).then((res) => {
          if (res.success) {
            this.detailInfo = res.result;
            // // console.log('企业详情', this.detailInfo);
            this.bankInfoParams.payeeName = this.detailInfo.enterpriseContacts
            this.bankInfoParams.enterpriseId = this.detailInfo.id
            this.companyIdentifyParams.enterpriseId = this.detailInfo.id
          } else {
            this.$message.error(res.message);
          }
        })
      },
      // 获取企业认证信息
      async getCompanyIndentifyInfo () {
        await getCompanyIndentifyInfo(this.id).then(res => {
          if (res.success && res.result !== null) {
            this.companyIdentifyParams = res.result
            this.sitePhotos2 = this.companyIdentifyParams.enterpriseSitePhotos2
            this.sitePhotos1 = this.companyIdentifyParams.enterpriseSitePhotos1
            this.enterpriseLicense = this.companyIdentifyParams.enterpriseLicense
            const expirationDate = new moment()
            expirationDate._d = new Date(this.companyIdentifyParams.expirationDate)
            this.companyIdentifyParams.remarkDate = expirationDate
            this.timeValue = this.companyIdentifyParams.expirationDate
          }
        })
        await getCompanyBankInfo(this.id).then(res => {
          // // console.log('企业开票信息',res)
          this.getKpInfo = res.result
          this.bankInfoParams.payeeName = this.companyIdentifyParams.realName;
          if (res.success && res.result !== null) {
            this.bankInfoParams.bankName = res.result.bankName;
            this.bankInfoParams.payeeBankNo = res.result.bankNo;
            this.bankInfoParams.subBankName = res.result.subBankName;
            this.bankInfoParams.id = res.result.id;
            this.detailInfo.enterpriseAddress = res.result.address;
            this.detailInfo.enterprisePhone = res.result.phone;
          }
        })
      },
      // 获取企业信息
      getEnterpriseBankInfo () {
        getCompanyBankInfo(this.id).then(res => {
          // // console.log('企业开票信息',res)
          if (res.success && res.result !== null) {
            this.bankInfoParams.bankName = res.result.bankName;
            this.bankInfoParams.payeeBankNo = res.result.bankNo;
            this.bankInfoParams.subBankName = res.result.subBankName;
            this.bankInfoParams.id = res.result.id;
            this.detailInfo.enterpriseAddress = res.result.address;
            this.detailInfo.enterprisePhone = res.result.phone;
          }
        })
      },
      /**
       * @description: 获取企业经营范围列表
       * @param : parameter 查询参数
       * @return: promise
       * @Author: HuangCheng
       */
      loadScopeList(parameter) {
        return getEnterpriseScopeList(Object.assign(parameter, {
          enterpriseId: this.id,
          ...this.searchParams
        }))
          .then(res => {
            this.pagination = {
              pageSize: res.result.pageSize,
              current: res.result.pageNo
            }
            return res.result
          })
      },
      /**
       * @description: 获取企业配置信息
       * @return: void
       * @Author: HuangCheng
       */
      getEnterpriseConfig() {
        getEnterpriseConfig(this.id).then((res) => {
          // // console.log('企业配置信息', res)
          if (res.success) {
            this.configDetail = res.result;
            // this.configDetail.quotas_bank = Number(this.configDetail.quotas_bank).toFixed(2)
          } else {
            this.$message.error(res.message);
          }
        })
      },
      /**
       * @description: 配置信息按钮点击事件
       * @param : 
       * @return: 
       * @Author: HuangCheng
       */
      handleConfigSubmit() {
        const description = this.configForm.getFieldsValue();
        if (this.modalType === 'detail') {
          this.modalType = 'edit';
          const data = this.configDetail;
          setTimeout(() => {
            this.configForm.setFieldsValue({
              remind_type: data.remind_type,
              remind_email: data.remind_email,
              remind_phone: data.remind_phone,
              account_balance_remind_email: data.account_balance_remind_email,
              account_balance_remind_phone: data.account_balance_remind_phone,
              account_balance_remind_type: data.account_balance_remind_type,
              bank_balance_remind_pric: data.bank_balance_remind_pric,
              bill_remind_email: data.bill_remind_email,
              bind_mail: data.bind_mail,
              bind_phone: data.bind_phone,
              early_warning_remind_email: data.early_warning_remind_email,
              quotas_bank: data.quotas_bank,
              quotas_wx: data.quotas_wx,
              quotas_zfb: data.quotas_zfb,
              recharge_remind_email: data.recharge_remind_email,
              recharge_remind_phone: data.recharge_remind_phone,
              recharge_remind_type: data.recharge_remind_type,
              service_charge_bear: data.service_charge_bear,
              service_charge_point: data.service_charge_point,
              tax_remind_email: data.tax_remind_email,
              wx_balance_remind_pric: data.wx_balance_remind_pric,
              zfb_balance_remind_pric: data.zfb_balance_remind_pric
            });
          }, 0)
          return;
        }
        this.configForm.validateFields((err, values) => {
          if (err) {
            return;
          }
          const description = this.configForm.getFieldsValue();
          description.bind_mail = description.bind_mail.trim();
          this.loading = true;
          updateEnterpriseConfig(this.id, description).then((res) => {
            this.loading = false;
            if (res.success) {
              this.modalType = 'detail';
              this.getEnterpriseConfig();
              this.$message.success('修改成功');
            } else {
              this.$message.error(res.message);
            }
          });
        });
      },
      /**
       * @description: 查询企业开票范围
       * @Author: HuangCheng
       */      
      handleToSearchScope() {
        this.$refs.table.refresh(true)
      },
      /**
       * @description: 重置开票范围查询表单
       * @return: void
       * @Author: HuangCheng
       */
      resetSearchScope() {
        this.searchParams = {
          status: ''
        };
        this.handleToSearchScope();
      },
      /**
       * @description: 打开添加弹窗并获取经营范围系统默认费率
       * @return: void
       * @Author: HuangCheng
       */
      handleModalVisible() {
        this.addModal = true;
        // 获取经营范围系统默认费率
        // getSysBusinessScopeRate().then((res) => {
        //   if (res.success) {
        //     this.addScopeForm.setFieldsValue({
        //       taxRate: res.result
        //     })
        //   } else {
        //     this.$message.error(res.message);
        //   }
        // });
        // 查询系统经营范围列表
        getBusinessScopeBox({
          businessScope: ''
        }).then((res) => {
          if (res.success) {
            this.scopeRateList = res.result;
          } else {
            this.$message.error(res.message);
          }
        });
      },
      // 选择开票范围（多选）
      handleChangeSelectCompany (value) {
      },
      handleSearchSelectCompany (value) {
        setTimeout(getBusinessScopeBox({
          businessScope: value
        }).then((res) => {
          if (res.success) {
            this.scopeRateList = []
            this.scopeRateList = res.result;
          } else {
            this.$message.error(res.message);
          }
        }), 1000)
      },
      /**
       * @description: 添加开票范围
       * @return: void
       * @Author: HuangCheng
       */
      handleScopeModalOk() {
        const description = this.addScopeForm.getFieldsValue();
        this.addScopeForm.validateFields((err, fieldsValue) => {
          if (err) {
            return;
          }
          const description = this.addScopeForm.getFieldsValue();
          // description.businessScopeId = description.businessScopeId.join(',');
          // // console.log(description)
          addEnterpriseBusinessScope(Object.assign(description, {
            enterpriseId: this.id,
            taxRate: 0.06,
            ...description
          })).then((res) => {
            if (res.success) {
              this.handleToSearchScope();
              this.$message.success('添加成功');
              this.addScopeForm.resetFields();
              this.addModal = false;
            } else {
              this.$message.error(res.message);
            }
          })
        });
      },
      handleScopeModalCancel() {
        this.addModal = false;
        this.addScopeForm.resetFields();
      },
      /**
       * @description: 冻结
       * @param : record 当前行数据
       * @return: void
       * @Author: HuangCheng
       */
      freeStaff(record) {
        const that = this;
        this.$confirm({
          title: `冻结`,
          content: h => <div>确定冻结<span style="color:red;">“{record.businessScopeName}”</span>?</div>,
          onOk() {
            freezeEnterpriseBusinessScope({id: record.id, status: '100202'}).then((res) => {
              if (res.success) {
                that.$message.success(`“${record.businessScopeName}”已冻结`);
                that.handleToSearchScope();
              } else {
                that.$message.error(res.message);
              }
            })
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
      },
      // 解冻
      returnOk (record) {
        const that = this;
        this.$confirm({
          title: `冻结`,
          content: h => <div>确定解冻<span style="color:red;">“{record.businessScopeName}”</span>?</div>,
          onOk() {
            return new Promise((resolve, reject) => {
              freezeEnterpriseBusinessScope({id: record.id, status: '100201'}).then(res => {
                if (res.success) {
                  that.$message.success(`“${record.businessScopeName}”已解冻`);
                  that.handleToSearchScope();
                  resolve();
                } else {
                  that.$message.error(res.message);
                  reject();
                }
              })
            })
          },
          onCancel() {
            // console.log('Cancel');
          },
          class: 'test',
        });
      },
      /**
       * 上传营业执照
       */
      handleChange(info, type) {
        if (info.fileList.length > 1) {
          info.fileList.shift()
        }
        // // console.log('info>>', info)
        // // console.log('type>>', type)
        if (info.file.status === 'uploading') {
          switch(type) {
              case 'enterpriseLicense' : this.loadingBusinessLicence = true;
              break;
              case 'sitePhotos1' : this.loadingidCardFront = true;
              break;
              case 'sitePhotos2' : this.loadingidCardOpsite = true;
              break;
            }
          return;
        }
        if (info.file.status === 'done' && (info.file.type === 'image/jpeg' || info.file.type === 'image/png' || info.file.type === 'image/jpg')) {
          getBase64(info.file.originFileObj, imageUrl => {
            let formdata = new FormData();
            switch(type) {
              case 'enterpriseLicense' : this.enterpriseLicense = imageUrl;
              formdata.append('file', info.fileList[0].originFileObj);
              formdata.append('fileType', '102111')
              uploadFile(formdata).then(res=> {
                this.companyIdentifyParams.enterpriseLicense = 'https://oss.zfycloud.com' + "/" + res.result
              })
              this.loadingBusinessLicence = false;
              break;
              case 'sitePhotos1' : this.sitePhotos1 = imageUrl;
              formdata.append('file', info.fileList[0].originFileObj);
              formdata.append('fileType', '102114')
              uploadFile(formdata).then(res=> {
                // // console.log('enterpriseLicense>>', res)
                this.companyIdentifyParams.sitePhotos1 = 'https://oss.zfycloud.com' + "/" + res.result
              })
              this.loadingidCardFront = false;
              break;
              case 'sitePhotos2' : this.sitePhotos2 = imageUrl;
              formdata.append('file', info.fileList[0].originFileObj);
              formdata.append('fileType', '102115')
              uploadFile(formdata).then(res=> {
                // // console.log('enterpriseLicense>>', res)
                this.companyIdentifyParams.sitePhotos2 = 'https://oss.zfycloud.com' + "/" + res.result
              })
              this.loadingidCardOpsite = false;
              break;
            }
          });
        }
      },
      beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
          this.$message.error('上传照片格式不正确!');
          return false
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('上传照片大小不能超过2MB!');
          return false
        }
        return isJpgOrPng && isLt2M;
      },
      changeTime(value, mode) {
        this.timeValue = mode
        this.companyIdentifyParams.remarkDate = value
      },
      /**
       * 提交企业认证信息
       */
      handleSubmitCompanyIdentify(e) {
        this.$refs.companyIdentifyRule.validate(valid => {
          if (valid) {
            this.companyIdentifyParams.enterpriseId = this.detailInfo.id
            // 处理时间
            let time = new Date(this.companyIdentifyParams.remarkDate._d).toJSON()
            this.companyIdentifyParams.expirationDate = new Date(+new Date(time) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            this.companyIdentifyParams.expirationDate = this.timeValue + ' 00:00:00'
            updateCompanyIndentifyInfo(this.companyIdentifyParams).then(res => {
              if (res.success) {
                this.$message.success('信息提交成功');
                this.getCompanyIndentifyInfo();
              } else {
                this.$message.error(res.message);
              }
            })
          } else {
            // // console.log('error submit!!');
            return false;
          }
        });
      },
      /**
       * 提交开票信息
       */
      handleSubmitBankInfo() {
        this.$refs.bankInfoRule.validate(valid => {
          if (valid) {
            if(this.getKpInfo!==null){
              let params = {
                address: this.detailInfo.enterpriseAddress,
                bankName: this.bankInfoParams.bankName,
                bankNo: this.bankInfoParams.payeeBankNo,
                creditCode: this.companyIdentifyParams.realCode,
                name: this.bankInfoParams.payeeName,
                phone: this.detailInfo.enterprisePhone,
                id: this.bankInfoParams.id
              }
              updateCompanyBankInfo(params).then(res => {
                if (res.success) {
                  this.$message.success('信息提交成功');
                  this.getEnterpriseBankInfo();
                } else {
                  this.$message.error('信息提交失败');
                }
              })
            } else {
              let params = {
                enterpriseId: this.bankInfoParams.enterpriseId,
                address: this.detailInfo.enterpriseAddress,
                bankName: this.bankInfoParams.bankName,
                bankNo: this.bankInfoParams.payeeBankNo,
                creditCode: this.companyIdentifyParams.realCode,
                name: this.bankInfoParams.payeeName,
                phone: this.detailInfo.enterprisePhone
              }
              addCompanyBankInfo(params).then(res => {
                if (res.success) {
                  this.$message.success('信息提交成功');
                  this.getEnterpriseBankInfo();
                } else {
                  this.$message.error('信息提交失败');
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      // 返利配置
      selectIsRebate () {
        if (this.rebateParams.rebatesState === '103401') {
          this.selectRebate = true
          this.rebateParams.rebatesCycle = '103501'
          this.rebateraneLists = [{
            ruleBegin: 0,
            ruleEnd: '',
            feePoint: ''
          }]
        } else {
          this.selectRebate = false
          this.rebateParams.rebatesCycle = undefined
        }
      },
      // 新增返利范围
      addRebate(index) {
        for (let i = 0; i < this.rebateraneLists.length; i++) {
          if (Number(this.rebateraneLists[i].ruleBegin) >= Number(this.rebateraneLists[i].ruleEnd)) {
            this.isAddRebate = false
            this.isNull = false
            this.$message.error(`返利范围第${i+1}条数据结束值必须大于起始值`);
          } else if (this.rebateraneLists[i].feePoint < 0) {
            this.isAddRebate = false
            this.isNull = false
            this.$message.error(`返利范围第${i+1}条数据扣点不能为负数`)
          } else if (this.rebateraneLists[i].ruleBegin === '' || this.rebateraneLists[i].ruleEnd === '' || this.rebateraneLists[i].feePoint === '') {
            this.isNull = false
            this.isAddRebate = false
            this.$message.error(`返利范围第${i+1}条数据不能为空`)
          } else {
            this.isAddRebate = true
            this.isNull = true
          }
        }
        // // console.log(this.isAddRebate, this.isNull)
        if (this.isAddRebate && this.isNull) {
          this.rebateraneLists.push({
            ruleBegin: this.rebateraneLists[index].ruleEnd,
            ruleEnd: '',
            feePoint: ''
          })
          this.isAddRebate = false
          this.isNull = false
        }
      },
      // 删除返利范围
      delRebate(index) {
        if(this.rebateraneLists.length > 1) {
          this.rebateraneLists.splice(index, 1)
        }
      },
      // 新增费用范围
      addCharge(index) {
        for (let i = 0; i < this.chargeLists.length; i++) {
          if (Number(this.chargeLists[i].ruleBegin) >= Number(this.chargeLists[i].ruleEnd)) {
            this.isAddCharge = false
            this.isNullCharge = false
            this.$message.error(`费用范围第${i+1}条数据结束值必须大于起始值`);
            break
          } else if (this.chargeLists[i].feePoint < 0) {
            this.isAddCharge = false
            this.isNullCharge = false
            this.$message.error(`费用范围第${i+1}条数据费用利率不能为负数`)
            break
          } else if (this.chargeLists[i].ruleBegin === '' || this.chargeLists[i].ruleEnd === '' || this.chargeLists[i].feePoint === '') {
            this.isNullCharge = false
            this.isAddCharge = false
            this.$message.error(`费用范围第${i+1}条数据不能为空`)
            break
          } else {
            this.isAddCharge = true
            this.isNullCharge = true
          }
        }
        if (this.isAddCharge && this.isNullCharge) {
          this.chargeLists.push({
            ruleBegin: this.chargeLists[index].ruleEnd,
            ruleEnd: '',
            feePoint: '',
            salesPoint: 0,
          })
          this.isAddCharge = false
          this.isNullCharge = false
        }
      },
      // 删除费用范围
      delCharge(index,item) {
        if(this.chargeLists.length > 1) {
          if (!item.id) {
            this.chargeLists.splice(index, 1)
            return
          }
          let that = this
          this.$confirm({
            title: '确定删除？',
            onOk() {
              return new Promise((resolve, reject) => {
                taxEnterpriseServiceRuleItemDel({id:item.id}).then((res) => {
                  if (res.success) {
                    that.$message.success(res.message);
                    that.getRebateRules()
                    resolve();
                  } else {
                    that.$message.error(res.message);
                    reject();
                  }
                });
              }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {
              // console.log('Cancel');
            },
          });
        }
      },
      // 下拉选择
      changeDls (value) {
        this.selectDls = value
        this.salesmanLists.forEach(item => {
          if (item.userId===value) {
            this.allowInput = item.settlementMode==='100402'?true:false
            if (item.settlementMode==='100402') {
              this.chargeLists.forEach(list => {
                list.salesPoint = 0
              })
            }
          }
        })
      },
      // 获取企业费用配置信息 
      getRebateRules () {
        feeQuery({enterpriseId:this.id,status: '102211'}).then(res => {
          if (res.success) {
            if(res.result != null){
              this.checkFeeResult = res.result
              this.checkFeeResult.forEach(item => {
              item.salesPoint = (item.salesPoint * 100).toFixed(4)
              item.feePoint = (item.feePoint * 100).toFixed(2)
            })
            }
          }
        })
        feeQuery({enterpriseId:this.id,status: '102201'}).then(res => {
          if (res.success&&res.result) {
            this.allowAuthor = false
            this.checkAuditFeeResult = res.result
            this.checkAuditFeeResult.forEach(item => {
              item.salesPoint = (item.salesPoint * 100).toFixed(4)
              item.feePoint = (item.feePoint * 100).toFixed(2)
            })
          } else {
            this.allowAuthor = true
          }
        })
      },
      // 新增费用范围
      handleSubmitEditCharge () {
        this.visibleExamineModalFee = true
      },
      handleRechargeModalOkFee () {
        // if (this.selectDls === '') {
        //   this.$message.error('请选择代理商')
        //   return
        // }
        let params = {
          enterpriseId: this.detailInfo.id,
          salesUserId: this.nowChannelUserId,
          configs: JSON.parse(JSON.stringify(this.chargeLists))
        }
        for (let i = 0; i < this.chargeLists.length; i++) {
          if (Number(this.chargeLists[i].ruleBegin) > Number(this.chargeLists[i].ruleEnd)) {
            this.isAddCharge = false
            this.$message.error(`费用范围第${i+1}条数据结束值必须大于起始值`);
            break
          } else if (this.chargeLists[i].feePoint < 0) {
            this.isAddCharge = false
            this.$message.error(`费用范围第${i+1}条数据费用利率不能为负数`);
            break
          } else if (this.chargeLists[i].ruleBegin === '' || this.chargeLists[i].ruleEnd === '' || this.chargeLists[i].feePoint === '') {
            this.isNullCharge = false
            this.$message.error(`费用范围第${i+1}条数据不能为空`);
          } else if (Number(this.chargeLists[i].ruleEnd) === 0) {
            this.isNullCharge = false
            this.$message.error(`费用范围第${i+1}条数据不能为零`);
            break
          } else {
            this.isAddCharge = true
            this.isNullCharge = true
          }
        }
        if (!this.isAddCharge||!this.isNullCharge) {
          return
        }
        this.confirmLoadingFee = true
        params.configs.forEach(item => {
          item.ruleBegin = Number(item.ruleBegin)
          item.ruleEnd = Number(item.ruleEnd)
          item.feePoint = item.feePoint
          item.salesPoint = item.salesPoint 
        })
        feeAdd(params).then(res => {
          this.confirmLoadingFee = false
          if (res.success) {
            this.$message.success(res.message);
            this.getRebateRules();
            this.getDlsInfos();
            this.handleExamineModalCancelFee();
          } else {
            this.$message.error(res.message);
          }
        })
      },
      handleExamineModalCancelFee () {
        this.visibleExamineModalFee = false
      },
      // 修改费用范围
      handleSubmitEditChargeEdit (e) {
        this.$refs.charge.validate(valid => {
          if (valid) {
            let params = {
              enterpriseId: this.detailInfo.id,
              items: JSON.parse(JSON.stringify(this.chargeLists))
            }
            for (let i = 0; i < this.chargeLists.length; i++) {
              if (Number(this.chargeLists[i].ruleBegin) > Number(this.chargeLists[i].ruleEnd)) {
                this.isAddCharge = false
                this.$message.error(`费用范围第${i+1}条数据结束值必须大于起始值`);
                break
              } else if (this.chargeLists[i].feePoint < 0) {
                this.isAddCharge = false
                this.$message.error(`费用范围第${i+1}条数据费用利率不能为负数`);
                break
              } else if (this.chargeLists[i].ruleBegin === '' || this.chargeLists[i].ruleEnd === '' || this.chargeLists[i].feePoint === '') {
                this.isNullCharge = false
                this.$message.error(`费用范围第${i+1}条数据不能为空`);
              } else if (Number(this.chargeLists[i].ruleEnd) === 0) {
                this.isNullCharge = false
                this.$message.error(`费用范围第${i+1}条数据不能为零`);
                break
              } else {
                this.isAddCharge = true
                this.isNullCharge = true
              }
            }
            if (this.isAddCharge && this.isNullCharge) {
            // if (1) {
              this.loading = true
              params.items.forEach(item => {
                item.ruleBegin = Number(item.ruleBegin)
                item.ruleEnd = Number(item.ruleEnd)
                item.feePoint = item.feePoint 
                item.salesPoint = item.salesPoint 
              })
              taxEnterpriseServiceRuleItemEdit(params).then(res => {
                this.loading = false
                if (res.success) {
                  this.$message.success(res.message);
                  this.getRebateRules();
                } else {
                  this.$message.error(res.message);
                }
              })
            }
          }
        })
      },
      // 费用范围审核
      freeAthour () {
        this.getRebateRules()
        this.visibleVerfiy = true
      },
      handleOkVerfiy () {
        this.confirmLoadingVerfiy = true
        let params = {
          enterpriseId: this.detailInfo.id,
          auditStatus: this.selectRadio
        }
        this.chargeLists.forEach(item => {
          if (item.id&&item.id!=='') {
            params.ids.push(item.id)
          }
        })
        feeAudit(params).then((res) => {
          this.confirmLoadingVerfiy = false
          if (res.success) {
            this.$message.success(res.message);
            this.getRebateRules();
            this.getDlsInfos();
            this.handleCancelVerfiy();
          } else {
            this.$message.error(res.message);
          }
        });
      },
      handleCancelVerfiy () {
        this.visibleVerfiy = false
      },
      // 获取企业返利配置信息
      // getRebateRules (serviceRuleType) {
      //   getRebateRules({enterpriseId: this.id,serviceRuleType: serviceRuleType}).then(res => {
      //     if (res.success) {
      //       if (serviceRuleType === '103601') {
      //         this.rebateraneLists = res.result.taxEnterpriseServiceRuleItems === null ? [{
      //           ruleBegin: 0,
      //           ruleEnd: '',
      //           feePoint: ''
      //         }] : res.result.taxEnterpriseServiceRuleItems
      //         this.rebateId = res.result.id
      //         this.rebateParams.rebatesState = res.result.rebatesState === null ? '103402' : res.result.rebatesState
      //         this.rebateParams.rebatesCycle = res.result.rebatesCycle
      //         this.rebateraneLists.forEach(item => {
      //           item.feePoint = (item.feePoint * 100).toFixed(2)
      //         })
      //         if (this.rebateParams.rebatesState !== '103402') {
      //           this.selectRebate = true
      //         } else {
      //           this.selectRebate = false
      //         }
      //       } else if (serviceRuleType === '103602') {
      //         this.chargeLists = res.result === null ? [
      //           {
      //             ruleBegin: 0,
      //             ruleEnd: 100000,
      //             feePoint: 0,
      //             salesPoint: 0
      //           },
      //           {
      //             ruleBegin: 100000,
      //             ruleEnd: 1000000,
      //             feePoint: 0,
      //             salesPoint: 0
      //           },
      //           {
      //             ruleBegin: 1000000,
      //             ruleEnd: 10000000,
      //             feePoint: 0,
      //             salesPoint: 0
      //           }
      //         ] : res.result.taxEnterpriseServiceRuleItems
      //         this.chargeId = res.result === null ? 0:res.result.id
      //         this.chargeLists.forEach(item => {
      //           item.feePoint = (item.feePoint * 100).toFixed(2)
      //           item.salesPoint = (item.salesPoint * 100).toFixed(2)
      //         })
      //       }
      //     }
      //   })
      // },
      // 提交返利配置
      handleSubmitRebate (e) {
        this.$refs.rebate.validate(valid => {
          if (valid) {
            if (this.selectRebate) {
              let params = {
                serviceRuleType: 103601,
                enterpriseId: this.detailInfo.id,
                rebatesCycle: this.rebateParams.rebatesCycle,
                rebatesState: this.rebateParams.rebatesState,
                taxEnterpriseServiceRuleItems: JSON.parse(JSON.stringify(this.rebateraneLists))
              }
              for (let i = 0; i < this.rebateraneLists.length; i++) {
                if (this.rebateraneLists[i].ruleBegin > this.rebateraneLists[i].ruleEnd) {
                  this.isAddRebate = false
                  this.$message.error(`返利范围第${i+1}条数据结束值必须大于起始值`);
                  break
                } else if (this.rebateraneLists[i].feePoint < 0) {
                  this.isAddRebate = false
                  this.$message.error(`返利范围第${i+1}条数据扣点不能为负数`)
                  break
                } else if (this.rebateraneLists[i].ruleBegin === '' || this.rebateraneLists[i].ruleEnd === '' || this.rebateraneLists[i].feePoint === '') {
                  this.isNull = false
                  this.$message.error(`返利范围第${i+1}条数据不能为空`)
                } else {
                  this.isAddRebate = true
                  this.isNull = true
                }
              }
              if (this.isAddRebate && this.isNull) {
                params.taxEnterpriseServiceRuleItems.forEach(item => {
                  item.feePoint = item.feePoint / 100
                })
                addRebateRules(params).then(res => {
                  if (res.success) {
                    this.$message.success('添加成功');
                    this.getRebateRules('103601');
                  } else {
                    this.$message.error(res.message)
                  }
                })
              }
            } else {
              let params = {
                serviceRuleType: 103601,
                enterpriseId: this.detailInfo.id,
                rebatesCycle: '',
                rebatesState: this.rebateParams.rebatesState,
                taxEnterpriseServiceRuleItems: []
              }
              addRebateRules(params).then(res => {
                // // console.log(res)
                if (res.success) {
                  this.$message.success('添加成功');
                  this.getRebateRules('103601');
                } else {
                  this.$message.error(res.message)
                }
              })
            }
          }
        })
      },
      // 提交范围配置
      handleSubmitCharge (e) {
        this.$refs.charge.validate(valid => {
          if (valid) {
            let params = {
              serviceRuleType: 103602,
              enterpriseId: this.detailInfo.id,
              rebatesCycle: '',
              rebatesState: '',
              taxEnterpriseServiceRuleItems: JSON.parse(JSON.stringify(this.chargeLists))
            }
            for (let i = 0; i < this.chargeLists.length; i++) {
              if (this.chargeLists[i].ruleBegin > this.chargeLists[i].ruleEnd) {
                this.isAddCharge = false
                this.$message.error(`费用范围第${i+1}条数据结束值必须大于起始值`);
                break
              } else if (this.chargeLists[i].feePoint < 0) {
                this.isAddCharge = false
                this.$message.error(`费用范围第${i+1}条数据费用利率不能为负数`)
                break
              } else if (this.chargeLists[i].ruleBegin === '' || this.chargeLists[i].ruleEnd === '' || this.chargeLists[i].feePoint === '') {
                this.isNullCharge = false
                this.$message.error(`费用范围第${i+1}条数据不能为空`)
              } else {
                this.isAddCharge = true
                this.isNullCharge = true
              }
            }
            if (this.isAddCharge && this.isNullCharge) {
              params.taxEnterpriseServiceRuleItems.forEach(item => {
                item.feePoint = item.feePoint / 100
                item.salesPoint = item.salesPoint / 100
              })
              addRebateRules(params).then(res => {
                if (res.success) {
                  this.$message.success('添加成功');
                  this.getRebateRules('103602');
                } else {
                  this.$message.error(res.message)
                }
              })
            }
          }
        })
      },
      // 修改返利配置
      handleSubmitEditRebate (e) {
        this.$refs.rebate.validate(valid => {
          if (valid) {
            if (this.selectRebate) {
              let params = {
                bucklePoint: 103601,
                enterpriseId: this.detailInfo.id,
                rebatesCycle: this.rebateParams.rebatesCycle,
                rebatesState: this.rebateParams.rebatesState,
                taxEnterpriseServiceRuleItems: JSON.parse(JSON.stringify(this.rebateraneLists)),
                id: this.rebateId
              }
              for (let i = 0; i < this.rebateraneLists.length; i++) {
                if (this.rebateraneLists[i].ruleBegin > this.rebateraneLists[i].ruleEnd) {
                  this.isAddRebate = false
                  this.$message.error(`返利范围第${i+1}条数据结束值必须大于起始值`)
                  break
                } else if (this.rebateraneLists[i].feePoint < 0) {
                  this.isAddRebate = false
                  this.$message.error(`返利范围第${i+1}条数据扣点不能为负数`)
                  break
                } else if (this.rebateraneLists[i].ruleBegin === '' || this.rebateraneLists[i].ruleEnd === '' || this.rebateraneLists[i].feePoint === '') {
                  this.isNull = false
                  this.$message.error(`返利范围第${i+1}条数据不能为空`)
                } else {
                  this.isAddRebate = true
                  this.isNull = true
                }
              }
              if (this.isAddRebate && this.isNull) {
                params.taxEnterpriseServiceRuleItems.forEach(item => {
                  item.feePoint = item.feePoint / 100
                })
                updataRebateRules(params).then(res => {
                  if (res.success) {
                    this.$message.success('修改成功');
                    this.getRebateRules('103601');
                  } else {
                    this.$message.error(res.message)
                  }
                })
              }
            } else {
              let params = {
                serviceRuleType: 103601,
                enterpriseId: this.detailInfo.id,
                rebatesCycle: '',
                rebatesState: this.rebateParams.rebatesState,
                taxEnterpriseServiceRuleItems: [],
                id: this.rebateId
              }
              updataRebateRules(params).then(res => {
                if (res.success) {
                  this.$message.success('修改成功');
                  this.getRebateRules('103601');
                } else {
                  this.$message.error(res.message)
                }
              })
            }
          }
        })
      },
      // 邮递地址
      handleSubmitaddress(e) {
        e.preventDefault();
        this.addressform.validateFields((err, values) => {
          if (!err) {
            // // console.log('Received values of form: ', values);
            // // console.log(this.emailAddress)
            if (this.emailAddress!==null) {
              saveEnterpriseInvoiceMailed(Object.assign(values, {enterpriseId: this.id, id: this.emailAddress.id})).then(res => {
                if (res.success) {
                  this.getEnterpriseInvoiceMailed();
                  this.$message.success(res.message)
                } else {
                  this.$message.error(res.message)
                }
              })
            } else {
              saveEnterpriseInvoiceMailed(Object.assign(values, {enterpriseId: this.id})).then(res => {
                if (res.success) {
                  this.getEnterpriseInvoiceMailed();
                  this.$message.success(res.message)
                } else {
                  this.$message.error(res.message)
                }
              })
            }
          }
        });
      },
      // 获取企业邮寄地址
      getEnterpriseInvoiceMailed () {
        getEnterpriseInvoiceMailed(this.id).then(res => {
          if (res.success) {
            // // console.log(res)
            this.emailAddress = res.result
            if (this.emailAddress !== null) {
              this.addressform.setFieldsValue({
                contactsAddress: this.emailAddress.contactsAddress,
                contactsName: this.emailAddress.contactsName,
                contactsPhone: this.emailAddress.contactsPhone,
              })
            }
          }
        })
      }
    },
    watch: {
      rebateraneLists: {
        handler (newV) {
          for(let i = 0; i < newV.length - 1; i++) {
            if (i < newV.length - 1) {
              newV[i+1].ruleBegin = newV[i].ruleEnd
            }
          }
          if (newV.length === 0) {
            this.rebateraneLists = [
              {
                ruleBegin: 0,
                ruleEnd: '',
                feePoint: ''
              }
            ]
          }
        },
        deep: true,
        immediate: true
      },
      chargeLists: {
        handler (newV) {
          for(let i = 0; i < newV.length - 1; i++) {
            if (i < newV.length - 1) {
              newV[i+1].ruleBegin = newV[i].ruleEnd
            }
          }
          // console.log('-->',newV)
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>
<style lang="less">
  .enterprise-detail {
    height: 100vh;
    display: flex;
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
      border-right: 2px solid #1890ff;
    }
    .detail-content {
      flex: 1;
      padding: 20px;
      overflow: auto;
      .detail-form {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
      .config-form {
        .ant-form-item-control {
          height: 40px;
        }
      }
    }
  }
</style>
<style lang="less" scoped>
.company-indentify{
  display: flex;
  .ant-form{
    width: 40%;
  }
}
.picture-common{
  width: 300px;
}
.extra-input{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}
.tbl{
  width: 1000px;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-collapse: separate;
  >tr{
    >td{
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 10px;
      &:last-child{
        border-right: 0;
      }
    }
    &:first-child{
      td:first-child{
        border-top: 0;
        border-left: 0;
      }
      td:last-child{
        border-top: 0;
        border-right: 0;
      }
    }
    &:last-child{
      td{
        border-bottom: 0;
      }
      td:first-child{
        border-left: 0;
      }
      td:last-child{
        border-right: 0;
      }
    }
  }
}
.range{
  list-style: none;
  padding: 0;
  width: 800px;
  border: 1px solid #ccc;
  li{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 20px 0;
    border: 1px solid #ccc;
    padding: 10px 0;
    &:first-child{
      input{
        border: 0;
        resize: none;
      }
    }
    input{
      margin: 0 10px;
      width: 200px;
    }
    .operation{
      width: 80px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
