<!--
 * @Description: 企业管理中心--企业管理
 * @Author: HuangCheng
 * @Date: 2020-02-18 15:19:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-02 19:49:46
 -->
<template>
  <div class="enterprise_manage">
    <!-- <a-card :bordered="false"> -->
    <commonTitle title="企业管理" />
    <div
      class="table-page-search-wrapper"
      style="margin-bottom: 20px;"
    >
      <a-form layout="inline">
        <a-row :gutter="2">
          <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="12">
            <a-form-item label="企业名称">
              <a-input
                v-model="queryParam.enterpriseName"
                placeholder="请输入企业名称"
                style="width: 230px"
              />
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="12">
            <a-form-item label="企业状态">
              <a-select
                style="width: 230px"
                placeholder="请选择企业状态"
                v-model="queryParam.status"
              >
                <a-select-option value="101101">未审核</a-select-option>
                <a-select-option value="101102">停用</a-select-option>
                <a-select-option value="101111">审核成功</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="12">
            <a-form-item label="联系人">
              <a-input
                v-model="queryParam.enterpriseContacts"
                placeholder="请输入联系人"
                style="width: 230px"
              />
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="12">
            <a-form-item label="联系电话">
              <a-input
                v-model="queryParam.enterprisePhone"
                placeholder="请输入联系电话"
                style="width: 230px"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            padding-right:24px;
          "
        >
          <div></div>
          <div>
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleToSearchEnterprise"
                >查询</a-button
              >
              <a-button
                style="margin-left: 8px; margin-right: 8px"
                icon="reload"
                @click="resetSearchEnterprise"
                >重置</a-button
              >
              <a-button
                type="primary"
                icon="plus"
                @click="() => this.handleModalVisible(true, 'add')"
                >添加企业</a-button
              >
            </span>
          </div>
        </div>
        
      </a-form>
    </div>

    <div class="page_tab">
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadDataList"
        bordered
        :scroll="{ x: 1300 }"
      >
        <span
          slot="action1"
          slot-scope="text, record"
          @click="toSalesmanData(record)"
          style="cursor: pointer; color: #1890ff"
          >{{ record.sysUserName }}</span
        >
        <span slot="action" slot-scope="text, record">
          <!-- <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" /> -->

          <a
            @click="confirmVerfiy(record)"
            v-if="record.status === '101101'"
            style="padding-right: 10px"
            >审核</a
          >

          <a @click="handleDropClick(record)">详情</a>
          <!-- <a-dropdown>
            <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
            <a-menu slot="overlay" @click="handleDropClick($event, record)">
              <a-menu-item key="detail">
                <a href="javascript:;">基本信息</a>
              </a-menu-item>
              
              
              <a-menu-item key="pause" v-if="record.status === '101111'">
                <a-popconfirm
                  title="禁用?"
                  @confirm="confirmStop(record)"
                  okText="是"
                  cancelText="否"
                >
                  <a href="#">禁用</a>
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item key="start" v-if="record.status === '101102'">
                <a-popconfirm
                  title="启用?"
                  @confirm="confirmStart(record)"
                  okText="是"
                  cancelText="否"
                >
                  <a href="#">启用</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown> -->
        </span>
      </s-table>
    </div>
    <!-- 审核 -->
    <a-modal
      title="选择审核平台"
      :visible="visibleVerfiy"
      :confirm-loading="confirmLoadingVerfiy"
      @ok="handleOkVerfiy"
      @cancel="handleCancelVerfiy"
    >
      <a-radio-group name="radioGroup" v-model="selectRadio">
        <a-radio :value="1"> 分时领英 </a-radio>
        <!-- <a-radio :value="0"> 结算平台 </a-radio>
          <a-radio :value="2"> 渠道商 </a-radio> -->
      </a-radio-group>
    </a-modal>
    <!-- 企业新增/编辑 -->
    <a-modal
      :title="modalType === 'add' ? '新增' : modalType === 'edit' ? '编辑' : ''"
      width="1000px"
      :visible="visibleCreateModal"
      :confirmLoading="confirmLoading"
      @ok="handleCreateModalOk"
      @cancel="handleCreateModalCancel"
    >
      <a-form :form="createForm" :hideRequiredMark="true">
        <a-row class="form-row" :gutter="16">
          <a-col :lg="24" :md="24" :sm="24">
            <p style="color: #333333; font-size: 16px">企业基本信息</p>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="16">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="企业名称"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-input
                placeholder="请输入企业名称"
                v-decorator="['enterpriseName', validatorRules.enterpriseName]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="行业类型"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-select
                class="w-100"
                placeholder="请选择行业类型"
                showSearch
                :defaultActiveFirstOption="false"
                :filterOption="false"
                @search="handleSearchSelectCompany"
                @change="handleChangeSelectCompany"
                v-decorator="[
                  'enterpriseIndustry',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择行业类型',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) in industryLists"
                  :value="item.id"
                  :key="index"
                  >{{ item.industryName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="16">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="省市区"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-cascader
                placeholder="请选择省市区"
                v-decorator="[
                  'residence',
                  {
                    rules: [
                      {
                        type: 'array',
                        required: true,
                        message: '请选择省市区',
                      },
                    ],
                  },
                ]"
                :options="residences"
                @change="selectArea"
                :show-search="{ filter }"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="详细地址"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-input
                placeholder="请输入详细地址(不包含省市区)"
                v-decorator="[
                  'enterpriseAddress',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入详细地址(不包含省市区)',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="16">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="联系人"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-input
                placeholder="请输入企业联系人"
                v-decorator="[
                  'enterpriseContacts',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入企业联系人',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="联系电话"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-input
                placeholder="请输入企业联系电话"
                v-decorator="[
                  'enterprisePhone',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入企业联系电话',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="16">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="纳税人类型"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-select
                class="w-100"
                placeholder="请选择纳税人类型"
                v-decorator="[
                  'taxpayerType',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择纳税人类型',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in taxpayerType"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <!-- 企业基本信息 -->
    <a-modal
      title="基本信息"
      width="100%"
      class="modal_detail"
      :footer="null"
      :visible="visibleDetailModal"
      @cancel="visibleDetailModal = false"
      :dialog-style="{ top: '0px' }"
    >
      <EnterpriseDetail
        v-if="visibleDetailModal"
        :id="currentId"
        :userId="userId"
      ></EnterpriseDetail>
    </a-modal>
    <!-- 修改 -->
    <a-modal
      title="修改提现方式"
      width="600px"
      destroyOnClose
      :visible="visibleExamineModalSh"
      @ok="handleRechargeModalOkSh"
      @cancel="handleExamineModalCancelSh"
    >
      <a-form :form="sendForm">
        <a-row class="form-row">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="提现方式"
              :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
            >
              <a-select
                placeholder="请选择提现方式"
                style="width: 100%"
                v-decorator="[
                  'autoWithdrawal',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择提现方式',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              >
                <a-select-option value="0">自动提现</a-select-option>
                <a-select-option value="1">手动提现</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <!-- </a-card> -->
  </div>
</template>
<script>
import Vue from "vue";
import STable from "@/components/table/";

import { ACCESS_TOKEN } from "@/store/mutation-types";
import EnterpriseDetail from "./components/EnterpriseDetail.vue";
import commonTitle from "../components/commonTitle";

import {
  getEnterpriseList,
  getEnterpriseBaseInfo,
  addEnterpriseBaseInfo,
  updateEnterpriseBaseInfo,
  examineEnterprise,
  stopCooperate,
  startCooperate,
  getEmployee,
  getSysRegion,
  getSysIndustry,
  updateWithdrawalModel,
  getAgentBox,
  getChanneList,
  getEnterprisePayConfig,
} from "@/api/api";

export default {
  name: "raskManage",
  components: {
    
    STable,
    EnterpriseDetail,
    commonTitle,
  },
  data() {
    const cellStyle = {
      maxWidth: "120px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      cursor: "pointer",
    };
    return {
      visibleVerfiy: false,
      confirmLoadingVerfiy: false,
      record: "",
      selectRadio: 1,
      visibleExamineModalSh: false,
      // 查询参数
      queryParam: {
        id: "",
        enterpriseName: "",
        enterpriseContacts: "",
        enterprisePhone: "",
        salesmanName: "",
      },
      salesmanLists: [],
      visibleCreateModal: false,
      createForm: this.$form.createForm(this),
      sendForm: this.$form.createForm(this),
      validatorRules: {
        enterpriseName: {
          rules: [
            {
              required: true,
              message: "请输入企业名称",
            },
          ],
        },
        realCode: {
          rules: [
            {
              required: true,
              message: "请输入营业执照号",
            },
          ],
        },
        serviceChargePoint: {
          rules: [
            {
              required: true,
              message: "请输入服务费扣点",
            },
          ],
        },
        enterpriseLicense: {
          rules: [
            {
              required: true,
              message: "请上传营业执照",
            },
          ],
        },
        enterpriseCardFront: {
          rules: [
            {
              required: true,
              message: "请上传身份证正面",
            },
          ],
        },
        enterpriseCardReverse: {
          rules: [
            {
              required: true,
              message: "请上传身份证反面",
            },
          ],
        },
      },
      confirmLoading: false,
      modalType: "add", // 弹窗类型，add:新增，detail：详情 edit: 编辑
      uploadLoading: {
        // 上传loading
        enterpriseLicense: false,
        enterpriseCardFront: false,
        enterpriseCardReverse: false,
      },
      uploadList: {
        enterpriseLicense: "",
        enterpriseCardFront: "",
        enterpriseCardReverse: "",
      },
      currentId: "",
      taxpayerType: [
        {
          value: "102401",
          label: "一般纳税人",
        },
        {
          value: "102411",
          label: "小规模纳税人",
        },
      ],
      // 表头
      columns: [
        {
          title: "企业名称",
          dataIndex: "enterpriseName",
          align: "center",
          width: "250px",
        },

        {
          title: "行业类型",
          dataIndex: "enterpriseIndustryText",
          align: "center",
          width: "180px",
          customCell: () => {
            return {
              style: cellStyle,
            };
          },
          customRender: (text) => (
            <a-tooltip placement="top" title={text}>
              {text}
            </a-tooltip>
          ),
        },
        {
          title: "联系人",
          dataIndex: "enterpriseContacts",
          width: "150px",
          align: "center",
          customCell: () => {
            return {
              style: cellStyle,
            };
          },
          customRender: (text) => (
            <a-tooltip placement="top" title={text}>
              {text}
            </a-tooltip>
          ),
        },
        {
          title: "联系电话",
          dataIndex: "enterprisePhone",
          width: "150px",
          align: "center",
        },
        {
          title: "企业地址",
          dataIndex: "detailedAddress",
          align: "center",
          width: "250px",
          customRender: (text, record) => (
            <a-tooltip placement="top" title={text.replace(/\//g, '') + record.enterpriseAddress}>
              <div style="max-width:250px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">
                {text.replace(/\//g, '')}
                {record.enterpriseAddress}
              </div>
            </a-tooltip>
          ),
        },

        
        
        {
          title: "企业状态",
          dataIndex: "status",
          align: "center",
          width: "160px",
          customCell: () => {
            return {
              style: cellStyle,
            };
          },
          customRender: (text) => (
            <a-tooltip
              placement="top"
              title={
                text === "101101"
                  ? "未审核"
                  : text === "101102"
                  ? "停用"
                  : text === "101111"
                  ? "审核成功"
                  : ""
              }
            >
              {text === "101101"
                ? "未审核"
                : text === "101102"
                ? "停用"
                : text === "101111"
                ? "审核成功"
                : ""}
            </a-tooltip>
          ),
        },
        {
          title: "企业余额(元)",
          dataIndex: "enterpriseBalanceAmount",
          align: "center",
          customRender: (text) => text.toFixed(2),
          width: "200px",
        },
        {
          title: "最近交易时间",
          dataIndex: "newPayTime",
          align: "center",
          width: "180px",
        },

        {
          title: "操作",
          dataIndex: "action",
          width: "200px",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      headers: {},
      url: {
        fileUpload: window._CONFIG["domianURL"] + "/api/tax/common/file/upload",
        imgerver: window._CONFIG["imgDomainURL"],
      },
      visibleDetailModal: false,
      residences: [],
      selectTheArea: {},
      industryLists: [],
      value: undefined,
      options: [
        {
          value: "zhejiang",
          label: "Zhejiang",
          children: [
            {
              value: "hangzhou",
              label: "Hangzhou",
              children: [
                {
                  value: "xihu",
                  label: "West Lake",
                },
                {
                  value: "xiasha",
                  label: "Xia Sha",
                  disabled: true,
                },
              ],
            },
          ],
        },
        {
          value: "jiangsu",
          label: "Jiangsu",
          children: [
            {
              value: "nanjing",
              label: "Nanjing",
              children: [
                {
                  value: "zhonghuamen",
                  label: "Zhong Hua men",
                },
              ],
            },
          ],
        },
      ],
      selectedUserId: "",
      salesmanLists: [],
      userId: "",
      enterprisePayConfigId: "",
      enterprisePayConfigIdPay: "",
    };
  },
  computed: {
    uploadAction() {
      return this.url.fileUpload;
    },
  },
  created() {
    console.log('进来了');
    window.localStorage.setItem('submenu','raskManage')
    const token = Vue.ls.get(ACCESS_TOKEN);
    this.headers = { "X-Access-Token": token };
    this.getEmployeelist();
    getAgentBox().then((res) => {
      if (res.success) {
        this.salesmanLists = res.result;
      }
    });
    this.getEnterprisePayConfigId();
  },
  methods: {
    //获取付款银行id
    getEnterprisePayConfigId() {
      getEnterprisePayConfig("103101").then((res) => {
        if (res.success) {
          this.enterprisePayConfigId = res.result.bankAccountId;
        }
      });
      getEnterprisePayConfig("103201").then((res) => {
        if (res.success) {
          this.enterprisePayConfigIdPay = res.result.bankAccountId;
        }
      });
    },
    // 获取业务员
    getEmployeelist() {
      getEmployee().then((res) => {
        if (res.success) {
          this.options = res.result;
          this.options.forEach((item) => {
            item.value = item.departName;
            item.label = item.departName;
            if (item.children.length > 0) {
              item.children.forEach((user) => {
                user.value = user.userId;
                user.label = user.realName;
              });
            } else {
              item.children = null;
              item.disabled = true;
            }
          });
        }
      });
    },
    // 连级选择和搜索
    onChange(value) {
      // this.selectedUserId = value
    },
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    // 修改提现方式
    handlegetBash(record) {
      // console.log(typeof record.autoWithdrawal)
      this.record = record;
      this.visibleExamineModalSh = true;
      setTimeout(() => {
        this.sendForm.setFieldsValue({
          autoWithdrawal: record.autoWithdrawal + "",
        });
      }, 300);
    },
    handleRechargeModalOkSh() {
      this.sendForm.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        let params = {};
        const description = this.sendForm.getFieldsValue();
        params = Object.assign({}, description, {
          id: this.record.id,
        });
        updateWithdrawalModel(params).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.handleExamineModalCancelSh();
            this.handleToSearchEnterprise();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    handleExamineModalCancelSh() {
      this.sendForm.resetFields();
      this.visibleExamineModalSh = false;
    },
    // 获取地区省份
    getSysRegion() {
      getSysRegion().then((res) => {
        if (res.success) {
          this.residences = res.result;
        }
      });
    },
    // 选择地区
    selectArea(value, selectedOptions) {
      // console.log(value, selectedOptions)
      this.selectTheArea = value;
    },
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    /**
     * @description: 上传前验证
     * @param : file 上传的文件
     * @return: boolean
     * @Author: HuangCheng
     */
    beforeUpload: function (file) {
      var fileType = file.type;
      if (fileType.indexOf("image") < 0) {
        this.$message.warning("请上传图片");
        return false;
      }
      //TODO 验证文件大小
    },
    /**
     * @description: 加载列表数据
     * @param : parameter 查询参数
     * @return: promise
     * @Author: HuangCheng
     */
    loadDataList(parameter) {
      
      return getChanneList(Object.assign( this.queryParam,parameter)).then(
        (res) => {
          
          return res.result;
        }
      );
    },
    /**
     * @description: 查询企业列表
     * @Author: HuangCheng
     */
    handleToSearchEnterprise() {
      this.$refs.table.refresh(true);
    },
    /**
     * @description: 重置查询表单
     * @return: void
     * @Author: HuangCheng
     */
    resetSearchEnterprise() {
      this.queryParam = {
        id: "",
        enterpriseName: "",
        enterpriseContacts: "",
        enterprisePhone: "",
        salesmanName: "",
      };
      this.handleToSearchEnterprise();
    },
    /**
     * @description: 操作下拉
     * @param :
     * @return:
     * @Author: HuangCheng
     */
    handleDropClick(record) {
      this.currentId = record.id;
      this.userId = record.sysUserId;
      // this.visibleDetailModal = true;//详情模态框
      // window.sessionStorage.setItem("enterpriseId", this.currentId);
      window.sessionStorage.setItem("userId", this.userId);
      window.localStorage.setItem("submenu", "raskDetail");
      this.$router.push("/raskDetail/" + this.currentId);
      // switch (key) {
      //   case "detail": // 基本详情
      //     this.currentId = record.id;
      //     this.userId = record.sysUserId;
      //     // this.visibleDetailModal = true;//详情模态框
      //     window.sessionStorage.setItem('enterpriseId',this.currentId)
      //     window.localStorage.setItem('submenu','raskDetail')
      //     this.$router.push('/raskDetail')
      //     break;

      //   default:
      //     break;
      // }
    },
    /**
     * @description: 上传事件
     * @param : info 上传文件
     * @Author: HuangCheng
     */
    handleChange(info, type) {
      if (info.file.status === "uploading") {
        this.uploadLoading[`${type}`] = true;
        return;
      }
      if (info.file.status === "done") {
        var response = info.file.response;
        this.uploadLoading[`${type}`] = false;
        if (response.success) {
          switch (type) {
            case "enterpriseLicense": // 营业执照
              this.createForm.setFieldsValue({
                enterpriseLicense: response.result,
              });
              this.uploadList.enterpriseLicense = response.result;
              break;
            case "enterpriseCardFront": // 身份证正面
              this.createForm.setFieldsValue({
                enterpriseCardFront: response.result,
              });
              this.uploadList.enterpriseCardFront = response.result;
              break;
            case "enterpriseCardReverse": // 身份证反面
              this.createForm.setFieldsValue({
                enterpriseCardReverse: response.result,
              });
              this.uploadList.enterpriseCardReverse = response.result;
              break;
            default:
              break;
          }
        } else {
          this.$message.warning(response.message);
        }
      }
    },
    /**
     * @description: 打开编辑弹窗
     * @param :
     * @return:
     * @Author: HuangCheng
     */
    handleEdit(record) {
      console.log(record);
      this.mdl = Object.assign({}, record);
      console.log("mdl", this.mdl);
      this.currentId = record.id;
      this.handleModalVisible(true, "edit");
      this.getEnterpriseBaseInfo(record.id);
      this.selectTheArea = [
        record.enterpriseProvince,
        record.enterpriseCity,
        record.enterpriseArea,
      ];
      let selectedSys = [0, record.sysUserId];
    },
    /**
     * @description: 获取企业详情
     * @param : id 企业id
     * @Author: HuangCheng
     */
    getEnterpriseBaseInfo(id) {
      getEnterpriseBaseInfo(id).then((res) => {
        // // console.log(res)
        if (res.success) {
          const result = res.result;
          this.picUrl = "Has no pic url yet";
          this.createForm.setFieldsValue({
            enterpriseName: result.enterpriseName,
            taxpayerType: result.taxpayerType,
            enterpriseContacts: result.enterpriseContacts,
            enterprisePhone: result.enterprisePhone,
            enterpriseAddress: result.enterpriseAddress,
            residence: [
              this.mdl.enterpriseProvince + "",
              this.mdl.enterpriseCity + "",
              this.mdl.enterpriseArea + "",
            ],
            enterpriseIndustry: this.mdl.enterpriseIndustry + "",
          });
          this.uploadList = {
            enterpriseLicense: result.enterpriseLicense,
            enterpriseCardFront: result.enterpriseCardFront,
            enterpriseCardReverse: result.enterpriseCardReverse,
          };
        } else {
          this.$message.error(res.message);
        }
      });
    },

    //添加逻辑
    handleModalVisible(isVisible, type) {
      this.modalType = type;
      this.getSysRegion();
      this.visibleCreateModal = isVisible;
      // 获取行业
      getSysIndustry().then((res) => {
        if (res.success) {
          this.industryLists = res.result;
          this.industryLists.forEach((item) => {
            item.id = item.id + "";
          });
        }
      });
    },
    /**
     * @description: 新增弹窗确定事件
     * @Author: HuangCheng
     */
    handleCreateModalOk() {
      // if (this.modalType === 'detail') { // 详情
      //   this.handleCreateModalCancel();
      //   return;
      // }
      this.createForm.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        const description = this.createForm.getFieldsValue();
        // console.log(description)
        // const params = Object.assign({}, description, {
        //   enterpriseLicense: this.uploadList.enterpriseLicense,
        //   enterpriseCardFront: this.uploadList.enterpriseCardFront,
        //   enterpriseCardReverse: this.uploadList.enterpriseCardReverse
        // });
        this.confirmLoading = true;

        if (this.modalType === "edit") {
          // 修改
          // description.sysUserId = this.selectedUserId[1]
          updateEnterpriseBaseInfo(
            Object.assign(
              {
                enterpriseProvince: this.selectTheArea[0],
                enterpriseCity: this.selectTheArea[1],
                enterpriseArea: this.selectTheArea[2],
              },
              description,
              {
                id: this.currentId,
                userId: this.mdl.userId,
              }
            )
          ).then((res) => {
            this.confirmLoading = false;
            if (res.success) {
              this.$message.success("修改成功");
              this.handleCreateModalCancel();
              this.handleToSearchEnterprise();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          // 新增
          // console.log('新增参数',description);
          // return

          // description.sysUserId = this.selectedUserId[1]
          addEnterpriseBaseInfo(
            Object.assign(description, {
              enterpriseProvince: this.selectTheArea[0],
              enterpriseCity: this.selectTheArea[1],
              enterpriseArea: this.selectTheArea[2],
              incomeId: this.enterprisePayConfigId,
              paymentId: this.enterprisePayConfigIdPay,
              enterpriseType: "100601",
            })
          ).then((res) => {
            this.confirmLoading = false;
            if (res.success) {
              this.$message.success("新增成功");
              this.handleCreateModalCancel();
              this.handleToSearchEnterprise();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    /**
     * @description: 关闭新增弹窗并清空表单数据
     * @Author: HuangCheng
     */
    handleCreateModalCancel() {
      this.createForm.resetFields();
      this.uploadList = {
        enterpriseLicense: "",
        enterpriseCardFront: "",
        enterpriseCardReverse: "",
      };
      this.visibleCreateModal = false;
    },
    /**
     * @description: 审核
     * @Author: HuangCheng
     */
    confirmVerfiy(record) {
      this.record = record;
      // this.visibleVerfiy = true;
      this.$confirm({
        title: '审核企业',
        content: '确认审核该企业？',
        onOk:()=> {
          return new Promise((resolve, reject) => {
            examineEnterprise({ id: this.record.id, tag: 1 }).then(
        (res) => {
          
          if (res.success) {
            this.$message.success(res.message);
            this.handleToSearchEnterprise();
            this.handleCancelVerfiy();
            resolve()
          } else {
            this.$message.error(res.message);
            reject()
          }
        }
      );
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    handleOkVerfiy() {
      this.confirmLoadingVerfiy = true;
      examineEnterprise({ id: this.record.id, tag: this.selectRadio }).then(
        (res) => {
          this.confirmLoadingVerfiy = false;
          if (res.success) {
            this.$message.success(res.message);
            this.handleToSearchEnterprise();
            this.handleCancelVerfiy();
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    handleCancelVerfiy() {
      this.visibleVerfiy = false;
    },
    /**
     * @description: 禁用
     * @Author: HuangCheng
     */
    confirmStop(record) {
      stopCooperate(record.id).then((res) => {
        if (res.success) {
          this.$message.success("已禁用");
          this.handleToSearchEnterprise();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @description: 启用
     * @Author: HuangCheng
     */
    confirmStart(record) {
      startCooperate(record.id).then((res) => {
        if (res.success) {
          this.$message.success("已启用");
          this.handleToSearchEnterprise();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //跳转到业务员数据
    toSalesmanData(record) {
      this.$router.push({
        path: "/salesmanData",
      });
    },
    // 查询企业详情
    handleSearchSelectCompany(value) {
      getSysIndustry({ industryName: value }).then((res) => {
        if (res.success) {
          this.industryLists = res.result;
          this.industryLists.forEach((item) => {
            item.id = item.id + "";
          });
        }
      });
    },
    handleChangeSelectCompany(value) {
      getSysIndustry({ industryName: value }).then((res) => {
        if (res.success) {
          this.industryLists = res.result;
          this.industryLists.forEach((item) => {
            item.id = item.id + "";
          });
        }
      });
    },
  },
  watch: {},
};
</script>
<style lang="less">
.page_tab {
}
.modal_detail {
  top: 0px;
  height: 100vh;
}
.enterprise_manage {
  // width: 100%;
  // height: 100%;
  // overflow-x: hidden;
  overflow: hidden;
  box-sizing: border-box;
  padding: 25px;
}
.detail-modal {
  top: 0;
  height: 100vh;

  .ant-modal-content {
    height: 100vh;
    .ant-modal-body {
      height: calc(100% - 55px);
      padding: 0;
    }
  }
}
</style>